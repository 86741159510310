@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ManropeMedium";
  src: local("ManropeMedium"), url(./fonts/Manrope-SemiBold.ttf) format("ttf");
}

@font-face {
  font-family: "ManropeLight";
  src: local("ManropeLight"), url(./fonts/Manrope-Light.ttf) format("ttf");
}

body {
  margin: 0;
  font-family: "ManropeLight", "Avenir", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** Rich Text Editor settings
*/

.editor-box h1 {
  font-size: 1.5em;
}

.editor-box h2 {
  font-size: 1.2em;
  font-weight: bold;
}

.editor-box a {
  color: #3b6501;
}

/** For removing spinners on number fields
*/
.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance: textfield !important;
}
